<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">单位统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel">单位名称:</span>
                <el-select
                    size="small"
                    v-model="unitCompId"
                    remote
                    :remote-method="getuserCompanyList"
                    filterable
                    clearable
                    placeholder="请至少输入两个字搜索"
                    style="width:100%"
                  >
                    <el-option
                      v-for="item in userCompanyList"
                      :key="item.compId"
                      :label="item.compName"
                      :value="item.compId"
                    ></el-option>
                  </el-select>
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                clearable
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                filterable
                size="small"
              ></el-cascader>
            </div>
            <div title="联系人电话" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">联系人电话:</span>
              <el-input
                v-model="compPhone"
                type="text"
                size="small"
                placeholder="请输入联系人电话"
                clearable
              />
            </div>
             <div title="法人姓名" class="searchboxItem ci-full">
              <span class="itemLabel">法人姓名:</span>
              <el-input
                v-model="compLegal"
                type="text"
                size="small"
                placeholder="请输入法人姓名"
                clearable
              />
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="单位名称" align="left" prop="compName" show-overflow-tooltip />
              <el-table-column label="上级单位" align="left" prop="compParentName" show-overflow-tooltip >
                <template slot-scope="scope">
                  {{
                  scope.row.compParentName || "--"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="所属行政区划"
                align="left"
                prop="areaName"
                show-overflow-tooltip
              />
               <el-table-column label="法人姓名" align="left" prop="name" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{
                  scope.row.compLegal || "--"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="联系人电话" align="left" prop="compPhone" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{
                  scope.row.compPhone || "--"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleLook(scope.row.compId)"
                  >查看</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "set/unitStatistics",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      userCompanyList: [],
      areaId: "",
      unitCompId: "",
      compPhone: "",
      compName: "",
      compLegal:'',
      
    };
  },
  created() {
    this.getareatree();
  },
  computed: {},
  methods: {
    /* 行政区划 */
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
     /* 单位名称 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyFJList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.userCompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.userCompanyList = [];
      }
    },
    /* 获取列表 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.unitCompId) {
        params.compNameId = this.unitCompId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.compPhone) {
        params.compPhone = this.compPhone;
      }
      if (this.compLegal) {
        params.compLegal = this.compLegal;
      }
      this.doFetch({
        url: "/sys/company/page-complist",
        params,
        pageNum
      });
    },
    // 查看
    handleLook(compId) {
      this.$router.push({
        path: "/web/set/UnitDetails",
        query: { 
          compId
         }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.unitname = ""),
            (this.areaId = ""),
            (this.compPhone = ""),
            (this.compName = ""),
            (this.pageNum = 1);
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true
    }
  }
};
</script>
<style lang="less">
.searchbox {
  padding: 0;
}
</style>
